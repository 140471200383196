const Footer = () => {
    return (
        <div className='flex flex-col items-center py-8 mt-10 bg-gray-100 text-center'>
            <h1 className='font-title text-6xl'>Barbert Pizza Co.</h1>
            <h2 className='font-sans text-2xl uppercase mb-5'>Elanora Heights</h2>
            <div className='flex flex-col md:flex-row font-light text-xl space-x-10'>
                <div className='flex flex-col justify-between text-center md:text-right md:w-72'>
                    <a href='https://www.google.com/maps/dir//Barbert+Pizza+Co.,+1%2F47-49+Elanora+Rd,+Elanora+Heights+NSW+2101/@-33.702032,151.2829439,16z' target='_blank' rel='noreferrer' className='flex flex-col mb-2 md:mb-0 md:hover:underline'>
                        <span>Shop 1, 47-49 Elanora Road</span>
                        <span>Elanora Heights, 2101</span>
                    </a>
                    <a href='tel:0299708775' target='_blank' rel='noreferrer' className='md:hover:underline mb-2 md:mb-0'>(02) 9970 8775</a>
                    <a href='mailto:blincartptyltd@gmail.com' target='_blank' rel='noreferrer' className='md:hover:underline mb-2 md:mb-0'>blincartptyltd@gmail.com</a>
                    {/* <div className='flex flex-row justify-center md:justify-end mb-5 md:mb-0'>
                        <a href='https://www.facebook.com/' target='_blank' rel='noreferrer' className='md:hover:underline'>Facebook</a>
                        <span className='w-8 text-center'>•</span>
                    </div> */}
                    <a href='https://www.instagram.com/barbertpizzaco_' target='_blank' rel='noreferrer' className='mb-5 md:mb-0 md:hover:underline'>Follow us on Instagram</a>
                </div>
                <div className='flex flex-row space-x-4 md:w-72 text-left'>
                    <div className='flex flex-col'>
                        <span>Mon:</span>
                        <span>Tue:</span>
                        <span>Wed:</span>
                        <span>Thu:</span>
                        <span>Fri:</span>
                        <span>Sat:</span>
                        <span>Sun:</span>
                    </div>
                    <div className='flex flex-col'>
                        <span>Closed</span>
                        <span>5pm - 9pm</span>
                        <span>5pm - 9pm</span>
                        <span>5pm - 9pm</span>
                        <span>5pm - 9:30pm</span>
                        <span>5pm - 9:30pm</span>
                        <span>5pm - 9pm</span>
                    </div>
                </div>
            </div>
            <div className='mt-8 text-sm font-light text-gray-400'><span>Website design © Rory Madden 2024</span></div>
        </div>
    )
}

export default Footer
